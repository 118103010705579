<template>
  <div
    class="item-dialog-preview"
  >
    <v-tabs-items
      style="background-color: transparent;"
      v-model="activeTab"
      :touchless="isMobileDevice"
    >
      <v-tab-item
        v-for="tab in tabs"
        :key="`tab-${tab.id}`"
      >
        <template
          v-if="activeTabName === 'card'"
        >
          <text-item-title
            style="max-width: 734px; margin: 0 auto;"
            readonly
            class="mb-0"
            v-if="showTitle && item.type === 'note'"
            v-model="item.info.title"
          />
          <lightbox-dialog
            v-model="item.content.originalUrl"
            v-if="item.type === 'image'"
            @closed="() => $emit('closed')"
            @opened="() => $emit('opened')"
          />
          <!-- <image-view
            :images="[item.content.originalUrl]"
            v-if="item.type === 'image'"
            v-model="zoomModel"
          /> -->
          <multipart-view
            :items="item.parts"
            v-else-if="item.type === 'multipart'"
          />
          <component
            v-else
            :is="item.type + 'Type'"
            :item="item"
            :item-id="itemId"
            :broken-ids="brokenIds"
            :dark="dark"
            :cut-text-content="false"
          />
        </template>
        <template
          v-else
        >
          {{ tab.label }}
        </template>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>
<script>
import { isMobile } from 'mobile-device-detect'

const textItemTitle = () => import('@/components/dialogs/item/textItemTitle')

const multipartView = () => import('@/components/dialogs/item/views/multipart')
const imageView = () => import('@/components/dialogs/item/views/image')
const lightboxDialog = () => import('@/components/dialogs/lightbox')
const noteType = () => import('@/components/ItemTypes/note')
const quoteType = () => import('@/components/ItemTypes/quote')

export default {
  props: {
    zoom: {
      default: () => 100
    },
    dark: {
      type: Boolean
    },
    tabs: {
      type: Array,
      required: true
    },
    activeTab: {
      required: true
    },
    itemId: {
      required: true
    },
    item: {
      required: true
    }
  },
  name: 'PreviewItemDialogContent',
  data: () => ({
    showTitle: true,
    brokenIds: []
  }),
  components: {
    textItemTitle,
    multipartView,
    imageView,
    noteType,
    quoteType,
    lightboxDialog
  },
  computed: {
    zoomModel: {
      get() {
        return this.zoom
      },
      set(val) {
        this.$emit('updateZoom', val)
      }
    },
    isMobileDevice() {
      return isMobile
    },
    activeTabName() {
      return this.tabs[this.activeTab].id
    }
  },
  watch: {
    activeTab() {
      this.showTitle = false
      this.$nextTick(() => {
        this.showTitle = true
      })
    }
  }
}
</script>
<style lang="scss">
.item-dialog-preview {
  padding: 0 0px 8px;
  height: 100%;

  .v-window,
  .v-window__container,
  .v-window-item {
    height: 100%;
    overflow: auto !important;
  }
}
</style>